.commonTable {
    width: 100%;
}
.commonTable thead tr {
    height: 45px;
    line-height: 45px;
    background-color: #f4f5f7;
}
.commonTable thead tr th {
    color: #666;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}
.commonTable tr span {
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
}
.commonTable td {
    text-align: center;
}
.commonTable .subTitle {
    color: #9d9fa5;
}
.commonTable input {
    text-align: center;
}
.commonTable input:disabled {
    background-color: transparent;
    border-bottom: none;
    color: #222;
}
.commonTable tbody tr {
    border-bottom: 1px solid #e3e3e6;
}
.commonTable tbody tr p {
    display: none;
}
