.pagination-current > a {
    background-color: #9a49f2;
    color: #fff;
    border: 1px solid #9d9fa5;
}

.pagination-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.pagination-item > a {
    min-width: 25px;
    width: fit-content;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
}

.pagination-arrow > a {
    width: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9d9fa5;
    font-size: 12px;
    cursor: pointer;
}
